export default {
  data(){
    return {
      currentId:''
    }
  },
  props:['menuList'],
  watch: {
    // 监视的属性
    '$route':{
      handler(newVal){
        console.log('$route');
        if(newVal){
          const currentPath = newVal && newVal.path || ''
          this.currentId = this.findItemsWithMatchingPath(this.menuList, currentPath)
          console.log(this.currentId,'this.currentId');
        }
      },
      immediate: true
    }
  },
  methods:{
    findItemsWithMatchingPath(data, path) {
      let id = ''
      function traverse(items) {
        for (const item of items) {
          if (item.path === path) {
            id = item.id
            return
          }
          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        }
      }
      traverse(data)
      return id
    }
   }
}