<template>
  <div class="container">
    <Title>服务网点</Title>
    <div class="map-content">
      <div class="map-con" ref="mapBox" style="width: 700px; height: 560px;"></div>
      <div class="info-con">
        <span class="info-address"><i class="el-icon-location"
            style="font-size: 40px; color: #BF1A20; margin-right:17px"></i>{{provinceName}}</span>
        <div class="info-list">
          <ul>
            <template v-if="companyList.length > 0">
              <li v-for="(item, index) in companyList" :key="index">
                <div class="company-name">{{item.deptName}} </div>
                <div class="company-address">公司地址：{{item.address}}</div>
                <div class="company-phone">电话: {{item.phone}}</div>
                <template v-if="item.qrCode">
                <div class="company-erweima">
                  <p><img :src="item.qrCode" alt=""></p>
                  <p style="">启动微信扫一扫</p>
                </div>
              </template>
              </li>
            </template>
            <template v-else>
              <li>暂无分公司</li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from './title.vue';
import * as echarts from 'echarts';//echarts 官网引入方法 
import '../assets/js/china'
import { getByProvinceCode } from '../assets/api/api.js'
const options = {
  series: [{
    name: '地图',
    type: 'map',
    map: 'china',
    symbol: 'pin',
    selectedMode: 'single',
    // center:[116.405338,39.915351], // 用于显示地图的物理位置中心

    label: {
      // 是否显示文字
      show: true,
      color: '#333',
      fontSize: 11,
    },
    roam: false, // 是否支持缩放git
    zoom: 1.2, // 地图放大倍数
    data:
      [ // 用于存放数据
        { name: '北京', zipCode: 110000, selected: true },
        { name: '上海', zipCode: 310000 },
        { name: '江苏', zipCode: 320000 },
        { name: '浙江', zipCode: 330000 },
        { name: '安徽', zipCode: 340000 },
        { name: '福建', zipCode: 350000 },
        { name: '江西', zipCode: 360000 },
        { name: '山东', zipCode: 370000 },
        { name: '河南', zipCode: 410000 },
        { name: '湖北', zipCode: 420000 },
        { name: '湖南', zipCode: 430000 },
        { name: '广东', zipCode: 440000 },
        { name: '广西', zipCode: 450000 },
        { name: '海南', zipCode: 460000 },
        { name: '重庆', zipCode: 500000 },
        { name: '四川', zipCode: 510000 },
        { name: '贵州', zipCode: 520000 },
        { name: '云南', zipCode: 530000 },
        { name: '西藏', zipCode: 540000 },
        { name: '陕西', zipCode: 610000 },
        { name: '甘肃', zipCode: 620000 },
        { name: '天津', zipCode: 120000 },
        { name: '青海', zipCode: 630000 },
        { name: '宁夏', zipCode: 640000 },
        { name: '新疆', zipCode: 650000 },
        { name: '香港', zipCode: 810000 },
        { name: '澳门', zipCode: 820000 },
        { name: '河北', zipCode: 130000 },
        { name: '山西', zipCode: 140000 },
        { name: '内蒙古', zipCode: 150000 },
        { name: '台湾', zipCode: 710000 },
        { name: '辽宁', zipCode: 210000 },
        { name: '吉林', zipCode: 220000 },
        { name: '黑龙江', zipCode: 230000 } 
      ],
    itemStyle: {
      areaColor: '#d7d7d9',
      borderColor: '#ffffff'
    },
    // 鼠标经过高亮显示
    emphasis: {
      label: {
        color: '#fff',
        fontSize: 11
      },
      itemStyle: {
        areaColor: '#BF1A20',
        borderColor: '#ffffff'
      },
    },
    // 点击选中区域快
    select: {
      itemStyle: {
        areaColor: '#BF1A20',
        color: '#fff'
      },
      label: {
        color: '#fff'
      }
    },

  }]
}
export default {
  name: 'AboutView',
  components: {
    Title
  },
  data() {
    return {
      provinceName: '北京',
      zipCode: 110000,
      companyList: []
    }
  },
  mounted() {
    this.mycharts = echarts.init(this.$refs.mapBox)
    this.mycharts.setOption(options)
    // 地图的点击事件
    this.mycharts.on('click', (params) => {
      // console.log(params, 'eeee')
      this.provinceName = params.data.name
      this.zipCode = params.data.zipCode
      this.getByProvinceCode()
    })
  },
  methods: {
    async getByProvinceCode () {
      const res = await getByProvinceCode(this.zipCode)
      this.companyList = res.data.deptList
      console.log(this.companyList, 'companyList')
    }
  },
  created() {
    this.getByProvinceCode()
  },
}
</script>


<style scoped>
.map-content{
  width: 1200px;
  height: 650px;
  padding: 34px 0 80px 0;
}
.map-con {
  width: 750px;
  height: 600px;
  margin: auto;
  float: left;
}

.info-con {
  width: 475px;
  max-height: 515px;
  height: auto;
  float: right;
  margin-top:28px;
  border: 1px solid #DBDBE1;
  background: linear-gradient(1deg, #F6F6F6 0%, #F9FAFA 100%, #F9FAFA 100%);
  border-radius: 14px;
  padding-bottom: 50px;
  /* 子元素靠右布局 */
}

.info-address {
  flex: 1;
  display: block;
  text-align: left;
  margin-left: 40px;
  margin-top: 30px;
  font-size: 24px;
  color: #BF1A20;
}

.info-list {
  max-height: 386px;
  height: auto;
  width: 100%;
  overflow: auto;
}

.info-list ul {
  margin-top: 30px;
  width: 390px;
  height: auto;
  margin-left: 40px;
}

.info-list ul li {
  border-bottom: 1px solid #EFF2F5;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.info-list ul li:last-child {
  border-bottom: 0px solid #EFF2F5;
}

.company-name,
.company-address,
.company-phone {
  text-align: left;
  margin-bottom: 5px;
}

.company-name {
  font-size: 18px;
  font-weight: 400;
  height: 28px;
  color: #111111;
  line-height: 28px;
}

.company-address,
.company-phone {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}
.company-erweima img{
  height: 98px;
}
.company-erweima p{
  text-align: center;
  width: 98px;
}
</style>
