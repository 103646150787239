// request/time.js

export function formatDateString(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  // const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}