<template>
    <div class="stories">
      <div class="right">
        <div class="title">
          <span>联合故事</span>
        </div>
        <div class="des">
          回首来时路，教育风险管理事业从无到有，从有向好，两代联合人以及广大风险管理员日拱一卒，矢志初心，以不止于保险和做长跑者的信念，不拒细壤，不择细流，用自己的智慧和担当一步步走了过来，书写了许许多多“无愧于国家，无愧于教育，无愧于自己”的感人篇章。
        </div>
        <div class="btn">
          <button class="go hoverShadow" @click="handleButtonClick">聆听我们的故事 <i class="el-icon-right" style="font-size: 14px;"></i></button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    handleButtonClick() {
      this.$router.push('/stories');
    }
  }
}
</script>

<style scoped>
.stories{
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: flex-end;
}
.right{
  width: 393px;
  height: 830px;
}
.title{
  width: 100%;
  height: 40px;
  padding-top: 162px;
  /* padding-left: 50px; */
  text-align: left;
  position: relative;
}
.title span{
  font-size: 36px;
  font-family: PingFang-SC-Semibold, PingFang-SC;
  font-weight: 500;
  color: #111111;
  line-height: 44px;
}

.title span::after {
  content: ''; /* 伪元素的内容为空 */
  position: absolute; /* 绝对定位 */
  left: 42px;
  bottom: -64px;
  width: 50px; /* 伪元素的宽度为 100% */
  height: 3px; /* 下划线高度为 2 像素 */
  background: #BF1A20;
}

.des{
  text-align: left;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 46px;
  padding-top: 80px;
}
.go{
  width: 160px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #BF1A20;
  background: #ffffff;
  color: #BF1A20;
  cursor: pointer;
}
.go:hover{
  width: 160px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #BF1A20;
  background: #BF1A20;
  color: #ffffff;
}
.btn{
  text-align: left;
  padding-top: 40px;
}

</style>