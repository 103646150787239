import axios from "axios";
import { getENVType } from "@/assets/js/utils";

const request = axios.create({
  baseURL: getENVType().url, // 设置请求的基础 URL
  timeout: 5000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json', // 设置请求头的 Content-Type
    // 可以在这里设置其他常用的请求头信息，例如 token 等
  },
});

// 请求拦截器
request.interceptors.request.use(
  config => {
    // 在请求发送前可以进行一些处理，例如添加 token 等
    return config;
  },
  error => {
    // 处理请求错误
    console.error('请求异常:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  response => {
    // 对响应数据进行处理，例如统一处理错误码等
    return response.data;
  },
  error => {
    // 处理响应错误
    console.error('请求异常:', error);
    return Promise.reject(error);
  }
);

export { request };
