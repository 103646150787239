<template>
    <div class="risk">
      <Title>风险管理</Title>
      <div class="risk-con">
        <ul>
          <li v-for="(item, index) in card" 
            :key="index"
            @mouseover="item.showHidden = true"
            @mouseleave="item.showHidden = false"
            >
            <transition name="fade">
              <div class="show" v-show="item.showHidden">
                <a :href="item.url">
                  <div class="f26">{{item.title}}</div>
                  <div class="f16">{{item.info}}</div>
                  <div class="jt"><img src="../assets/images/jt.png" alt=""></div>
                </a>
              </div>
            </transition>
            <div class="card" :style="{ backgroundImage: `url(${item.imgUrl})` }">
              <span class="title">{{item.title}}</span>
              <span class="des">{{item.des}}</span>
            </div> 
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import Title from './title.vue';
export default {
  data () {
    return {
      card: [
        {title: '产品与服务', des: '严选安全保障产品与服务，科学有效转移教育行业风险', info: '根据教育行业风险转移需求，设计并优化了校方责任保险、学生意外伤害综合保险、校园食品安全责任保险等十多个教育保险产品，科学有效地转移教育行业风险。', imgUrl: require('../assets/images/cpyfw.png'), url: '/riskmanage/productServices',  showHidden: false},
        {title: '解决方案', des: '“保险+服务+数字化”的全方位风险管理解决方案', info: '针对校园、企业、风险顾问等不同行业场景，提供“保险+服务+数字化”的全方位风险管理解决方案，助力合作伙伴与联合互惠互赢，共同创建良好的合作生态', imgUrl: require('../assets/images/jjfa.png'), url: '/riskmanage/solutionview',  showHidden: false},
        {title: '联合学堂', des: '分享教育行业的风险知识、经典案例、理论研究', info: '联合专注风险管理服务，根据实践经验总结风险知识、经典案例，并不断致力于相关理论研究，为行业发展提供科学报告，推教育保险事业良好发展。', imgUrl: require('../assets/images/lhxt.png'), url: '/riskmanage/LianheSchool', showHidden: false},
        {title: '活动专栏', des: '全国开放日活动宣传、安全讲座、灾难应急演练等', info: '充分履行教育行业风险管理顾问和保险经纪人的职能职责，响应宣传日、宣传月号召，组织知识普及讲座、火灾地震应急演练等活动，为校园风险综合防控能力提供更多保障。', imgUrl: require('../assets/images/hdzl.png'), url: '/riskmanage/eventColumn', showHidden: false},
      ]
    }
  },
  components:{
    Title
  },
  methods: {
     
    }
}
</script>

<style scoped>
.risk{
  width: 1200px;
}
.risk-con{
  width: 1200px;
  height: 690px;
  padding: 64px 0 80px 0;
  cursor: pointer;
}
.card{
  width: 599px;
  height: 262px;
  background: #f6f6f6;
}
.risk-con ul{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  list-style-type: none;
  padding: 0;
}
.risk-con ul li{
  width: 599px;
  margin-right: 2px; /* 默认的间距为10px */
  box-sizing: border-box; /* 确保边框和内边距不会增加元素宽度 */
  margin-bottom: 2px;
}
.risk-con ul li:nth-child(2n) {
  margin-right: 0; /* 第四个、第八个... li 元素的间距为0 */
}
.title{
  width: 151px;
  height: 70px;
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 31px;
  display: block;
  text-align: left;
  margin-left: 36px;
  padding-top: 39px;
}
.des{
  display: block;
  width: 210px;
  height: 62px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #636363;
  line-height: 25px;
  text-align: left;
  margin-left: 36px;
  margin-top: 11px;
}
.show{
  width: 557px;
  height: 224px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: block;
}

.f26{
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  text-align: left;
  margin-left: 34px;
  margin-top: 30px;
}
.f16{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #636363;
  text-align: left;
  margin-left: 34px;
  margin-right: 34px;
  margin-top: 10px;
}
.jt{
  text-align: left;
  margin-left: 34px;
  margin-top: 18px;
}

</style>