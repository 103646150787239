import Vue from 'vue'
import Vuex from 'vuex'
import { list } from '@/assets/api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList:[]
  },
  getters: {
    getMenuList: state => state.menuList
  },
  mutations: {
    SET_MENU_LIST(state, list) {
      state.menuList = list;
    },
  },
  actions: {
    async setMenuList({ commit }) {
      // context.commit('SET_MENU_LIST');
      try {
        const res = await list(0)
        commit('SET_MENU_LIST', res.data)
        sessionStorage.setItem('menu', JSON.stringify(res.data))
      }catch(err){
        console.log(err)
      }
    },
  },
  modules: {
  }
})
