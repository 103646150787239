<template>
   <div id="app">
    <Kf></Kf>
    <Container :boxShadow="'0px 8px 12px 0px rgba(230,230,230,0.5)'" style="position: relative"><Header :menuList="menuList"/></Container>
    
    <router-view v-if="menuList.length" :menuList="menuList"/>
    
    <Container><Footer /></Container>
  </div>
  <!-- <div id="app" v-if="isPc">
    <Kf></Kf>
    <Container :boxShadow="'0px 8px 12px 0px rgba(230,230,230,0.5)'" style="position: relative"><Header :menuList="menuList"/></Container>
    <router-view v-if="menuList.length" :menuList="menuList"/>
    <Container><Footer /></Container>
  </div>
  <div id="app" v-else>
      <MobileHeader></MobileHeader>
      <router-view/>
      <MobileFooter/> 
  </div> -->
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Container from './components/container.vue'
import { list } from '@/assets/api/api'
import MobileHeader from './mobile/components/MobileHeader.vue'
import MobileFooter from './mobile/components/MobileFooter.vue'
import Kf from './components/Kf.vue'

export default {
  name: "App",
  data(){
    return {
      menuList:[],
      isPc: false
    }
  },
  created(){
    this.getMenuList(),
    this.isPc = this.isphone(); // 在组件创建时检测设备类型并设置 isPc 的值
  },
  components: {
    Footer, // 注册 Footer 组件
    Header,
    Container,
    MobileHeader,
    MobileFooter,
    Kf
  },
  methods:{
    async getMenuList(){
      const res = await list(0)
      this.menuList = res.data
      console.log(this.menuList, 'this.menuList')
    },
    isphone () {
      const userAgentInfo = navigator.userAgent;
      const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      let flag = true;
      for (let i = 0; i < Agents.length; i++) {
      if (userAgentInfo.indexOf(Agents[i]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
    },
  },
  computed: {
    headerComponent () {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return isMobile ? 'MobileHeader' : 'Header';
    },
    footerComponent () {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return isMobile ? 'MobileFooter' : 'Footer';
    },
    nothing () {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return isMobile ? 'MobileFooter' : 'Footer';
    }
  },
};
</script>
<style>

</style>