import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LianheSchoolDetail  from '@/components/LianheSchoolDetail.vue';
import NewsDetail  from '@/components/NewsDetail.vue';

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // beforeEnter: (to, from, next) => {
    //   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    //   if (isMobile) {
    //     window.location.href = '/mobileHomepage';
    //   } else {
    //     next();
    //   }
    // }
  },
  {
    path: '/about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    children:[
      {
        path: 'profile',
        name: 'company',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path: 'jobs',
        name: 'jobs',
        component: () => import(/* webpackChunkName: "jobs" */ '../views/about/jobs.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    alias: ['/news/:id'],
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue'),
    children:[
      {
        path:'',
        name:'LianheNews',
        component: () => import('../components/LianheNews.vue')
      }
    ]
  },
  {
    path: '/disclosure',
    name: 'disclosure',
    component: () => import(/* webpackChunkName: "disclosure" */ '../views/DisclosureViev.vue')
  },
  {
    path: '/internetInsurance',
    name: 'internetInsurance',
    component: () => import(/* webpackChunkName: "disclosure" */ '../views/InternetInsurance.vue')
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/UibStories.vue')
  },
  
  {
    path: '/riskmanage',
    name: 'riskmanage',
    component: () => import(/* webpackChunkName: "productServices" */ '../views/riskmanage/index.vue'),
    children:[
      {
        path: 'productServices',
        name: 'productServices',
        component: () => import(/* webpackChunkName: "productServices" */ '../views/riskmanage/ProductServices.vue'),
      },
      {
        path: 'solutionview',
        name: 'solutionview',
        component: () => import(/* webpackChunkName: "solutionview" */ '../views/riskmanage/SolutionView.vue')
      },
      {
        path: 'lianheSchool',
        name: 'lianheSchool',
        component: () => import(/* webpackChunkName: "lianheSchool" */ '../views/riskmanage/LianheSchool.vue'),
      },
      {
        path: 'eventColumn',
        name: 'eventColumn',
        component: () => import(/* webpackChunkName: "eventColumn" */ '../views/riskmanage/EventColumn.vue')
      }
    ]
  },
  {
    path: '/riskmanage/lianheSchool/detail/:id',
    name: 'LianheSchoolDetail',
    component: LianheSchoolDetail,
  },
  {
    path: '/news/detail/:id',
    name: 'NewsDetail',
    component: NewsDetail,
  },
  {
    path: '/stories/details/:id',
    name: 'StoriesDetails',
    component: () => import('../components/StoriesDetails.vue')
  },
  {
    path: '/riskmanage/eventColumn/list_1',
    name: 'list_1',
    component: () => import('../views/riskmanage/eventDetails/list_1.vue')
  },
  {
    path: '/riskmanage/eventColumn/list_2',
    name: 'list_2',
    component: () => import('../views/riskmanage/eventDetails/list_2.vue')
  },
  {
    path: '/riskmanage/eventColumn/list_3',
    name: 'list_3',
    component: () => import('../views/riskmanage/eventDetails/list_3.vue')
  },
  {
    path: '/stories/list',
    name: 'StoriesList',
    component: () => import('../views/StoriesList.vue')
  },
  {
    path: '*',
    name: 'Nofound',
    component: () => import('../components/NoFound.vue')
  },
  {
    path: '/mobileHomepage',
    name: 'mobileHomepage',
    component: () => import('../mobile/views/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 如果有保存的滚动位置，则直接返回
    } else {
      // 没有保存的滚动位置时，将页面滚动至指定元素或坐标
      return { x: 0, y: 0 }; // 这里的 { x: 0, y: 100 } 表示将页面滚动至 x 坐标为0，y坐标为100的位置
    }
  }
})


export default router
