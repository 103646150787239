import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import './assets/css/reset.css'
import './assets/css/base.css'
import './plugins/element.js'
// import VueVideoPlayer from 'vue-video-player';
// import('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')


// 引入 element ui 的组件、并手动注册
import { Carousel, CarouselItem, Icon, Pagination, Tabs, TabPane, Empty, Dialog, Popover, Skeleton, SkeletonItem, Backtop } from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(Popover)
// Vue.use(VueVideoPlayer);
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Backtop)



Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
