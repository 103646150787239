<template>
  <div class="kf">
      <div class="kf_icon">  
        <el-popover
          placement="left"
          width="240"
          height="230"
          trigger="hover">
          <div class="kf_poperDiv">
            <p style="margin-top: 30px;"><img src="../assets/images/erweima_img.png" alt=""></p>
            <p>扫码关注北京联合</p>
          </div>
          <div class="kf_list_div" slot="reference"> 
            <img 
              :src="currentImageUrl"
              @mouseover="changeImage(require('@/assets/images/erweima_hover.png'))"
              @mouseout="resetImage"  
              alt=""
              />
          </div>
        </el-popover>
        <el-popover
          placement="left"
          width="240"
          height="230"
          trigger="hover">
          <div class="hover-phone">
            <p style="padding-top: 30px;"><img src="../assets/images/phone.png" alt=""></p>
            <p class="phone-font">4008-900-900</p>
            <p class="call">电话联系客户经理</p>
          </div>
          <div class="kf_list_div" slot="reference"> 
            <img 
              :src="currentImageUrl_p"
              @mouseover="changeImage_p(require('@/assets/images/phone_hover.png'))"
              @mouseout="resetImage_p"  
            />
          </div>
        </el-popover>
        <div class="kf_list_div"> <img src="../assets/images/black_g.png" alt="" @click="scrollToTop()"></div>
      </div>
    </div>
</template>

<script>
export default {
 data () {
  return {
      currentImageUrl: require('@/assets/images/erweima_g.png'),
      currentImageUrl_p: require('@/assets/images/phone_g.png'),
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    changeImage(newImageUrl) {
      this.currentImageUrl = newImageUrl;
    },
    resetImage() {
      this.currentImageUrl = require('@/assets/images/erweima_g.png')
    },
    changeImage_p(newImageUrl) {
      this.currentImageUrl_p = newImageUrl;
    },
    resetImage_p() {
      this.currentImageUrl_p = require('@/assets/images/phone_g.png')
    }
  },
}
</script>

<style scoped>
.kf{
  width: 64px;
  height: 209px;
  background: #FFFFFF;
  box-shadow: 0px 2px 19px 0px rgba(0,0,0,0.14);
  border-radius: 38px;
  position: fixed;
  top: 70%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.kf_icon{
  width: 64px;
  height: 150px;
  margin-top: 35px;
}

.kf_icon img {
  max-width: 100%;
  height: auto;
}
.kf_poperDiv{
  width: 210px;
  height: 210px;
  text-align: center;
}
.list_icon{
  margin-bottom: 32px;
}
.hover-phone{
  width: 210px;
  height: 210px;
  text-align: center;
}
.phone-font{
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 22px;
}
.hover-phone p{
  margin-bottom: 15px;
}
.kf_list_div{
  height: 50px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>