<template>
  <div class="container">
    <div class="voice">
      <div class="con">
        <div class="left">
          <div class="title">
            <span>联合心声</span>
          </div>
          <div class="info">
            <p>
              二十余年来，我们始终坚守“源于教育、立足教育、服务教育”的经营理念，致力于成为教育行业专业的风险管理和保险经纪服务商，责任先行，多措并举，切实降低校园风险，护航师生安全。在社会各界的信任和支持下，联合经纪目前已建立了覆盖全国的专业化教育行业风险管理服务体系，取得了教育受益、政府放心、社会满意的显著成果。
            </p>
            </div>
        </div>
        <div class="right">
          <div class="past">
            <div class="right_tit">不忘初心</div>
            <div class="right-des">孩子是未来的希望，联合经纪将永葆初心，持续提升在校园风险管理领域的专业化能力，积极发挥保险服务在降低、化解学生伤害事故方面的作用，在校园风险管理的事前预防、事中监控以及事后服务方面提供全流程、全周期的多元化服务，为学生安全成长贡献力量。</div>
          </div>
          <div class="future">
            <div class="future-tit">展望未来</div>
            <div class="future-des">未来，联合经纪将以追求高质量发展为目标，不断完善“风险管理+保险经纪”的全域服务体系，做深、做透、做精教育领域风险管理，依托科技赋能，打造风险管理与风险转移的数字化场景服务平台，用数字化转型驱动新的价值发展，助力教育行业风险管理发展。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.voice{
  height: 924px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.con{
  width: 1200px;
  min-width: 1200px;
  height: 924px;
  display: flex;
  position: relative;
}
.left{
  text-align: left;
}
.title{
  width: 393px;
  height: 40px;
  margin-top: 195px;
}
.title span{
  font-size: 36px;
  font-family: PingFang-SC-Semibold, PingFang-SC;
  font-weight: 500;
  color: #111111;
  line-height: 44px;
  position: absolute; /* 绝对定位 */
}

.title span::after {
  content: ''; /* 伪元素的内容为空 */
  position: absolute; /* 绝对定位 */
  left: 42px;
  bottom: -15px;
  width: 50px; /* 伪元素的宽度为 100% */
  height: 3px; /* 下划线高度为 2 像素 */
  background: #BF1A20;
}
.title{
  margin-bottom: 60px;
}
.info{
  width: 320px;
  height: 447px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 46px;
  text-align: left;
  word-wrap: break-word;
  overflow: hidden;
  text-align: justify;
  word-break: break-all; 
  text-justify: distribute;

}
.right{
  display: flex;
  width: 800px;
  margin-left:auto;
  margin-top: 530px;
}
.right_tit{
  width: 320px;
  height: 39px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 39px;
  text-align: left;
  margin-left: 38px;
}
.right-des{
  width: 320px;
  height: 280px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  text-align: left;
  margin-left: 35px;
  margin-top: 24px;
  overflow: hidden;
  text-align: justify;
  word-break: break-all; 
  text-justify: distribute;
}
.future-tit{
  width: 320px;
  height: 39px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #111111;
  line-height: 39px;
  text-align: left;
  margin-left: 80px;
}
.future-des{
  width: 320px;
  height: auto;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 36px;
  text-align: left;
  margin-left: 80px;
  margin-top: 24px;
  word-wrap: break-word;
  overflow: hidden;
  text-align: justify;
  word-break: break-all; 
  text-justify: distribute;

}
</style>