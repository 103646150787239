<template>
  <div class="home-container">
    <!-- banner -->
    <containerIndex :img="bannerUrl">
      <div class="banner-div" >
        <div class="banner">
        <div class="slogan">{{title}}</div>
        <div class="slogan-tips">{{subtitle}}</div>
        <div class="dataNumber">
          <ul>
            <li>
              <div class="data-1">
                <span class="f60"><count-to :start-val="0" :end-val="schoolNumber" /></span>万+
              </div>
              <div class="f20">校园服务</div>
            </li>
            <li>
              <div class="data-1"><span class="f60"><count-to :start-val="0" :end-val="clientNumber" :duration="10000"/></span>亿+</div>
              <div class="f20">客户服务</div>
            </li>
            <li>
              <div class="data-1"><span class="f60"><count-to :start-val="0" :end-val="outletsNumber"
                    separator="" /></span>+</div>
              <div class="f20">分支机构</div>
            </li>
            <li>
              <div class="data-1"><span class="f60"><count-to :start-val="0" :end-val="consultantNumber"
                    separator="" /></span>万+</div>
              <div class="f20">案件服务</div>
            </li>
          </ul>
        </div>
      </div>
        <el-carousel :interval="5000" arrow="always" >
            <el-carousel-item v-for="item in urlItems" :key="item.id">
              <img :src="item.url" alt="">
            </el-carousel-item>
          </el-carousel>
      </div>
      <!--  -->
    </containerIndex>
    <!-- src: require('@/assets/images/test.mp4'), -->
    <Container :img="require('@/assets/images/home-1.png')" :padding-top="'80px'" :position="'bottom center'">
      <LHvoices/>
    </Container>
    <!-- 公司介绍 -->
    <Container :img="require('@/assets/images/home-2.png')" :padding-top="'80px'" :position="'bottom center'">
      <About />
    </Container>
    <!-- 风险管理 -->
    <Container>
    <RiskManage />
    </Container>
    <!-- 新闻动态 -->
    <Container color="#f6f6f6">
      <News />
    </Container>
    <!-- 引用地图开始 -->
    <Container><Map /></Container>
    <!-- 引用地图结束 -->
    <!-- 引用合作伙伴 -->
    <Container color="#f6f6f6">
      <Partners />
    </Container>
  </div>
</template>

<script>
// @ is an alias to /src
import { bannerList } from '../assets/api/api.js'
import Map from '@/components/Map.vue'
import Partners from '@/components/Partners.vue'
import CountTo from 'vue-count-to'
import RiskManage from '@/components/RiskManage.vue'
import News from '@/components/News.vue'
import About from '@/components/Stories.vue'
import Container from '@/components/container.vue'
import containerIndex from '@/components/containerIndex.vue'
import LHvoices from '@/components/LHvoices.vue'
import MyMixin from '@/mixin/mixin'
import { mapGetters } from 'vuex'

export default {
  mixins:[MyMixin],
  name: 'HomeView',
  created(){
    this.getBannerList()
  },
  components: {
    Map,
    Partners,
    CountTo,
    RiskManage,
    News,
    About,
    Container,
    LHvoices,
    containerIndex
  },
  data() {
    return {
      carouselIndex: 0,
      schoolNumber: 20,
      clientNumber: 1000,
      outletsNumber: 1000,
      consultantNumber: 10000,
      bannerInfo:[],
      bannerUrl: '',
      indexId: null,
      title: '',
      subtitle: '',
      urlItems: []
    }
  },
  methods: {
    async getBannerList () {
      console.log(this.currentId,'this.currentId');
      const res = await bannerList(this.currentId);
      this.bannerInfo = res.data
      console.log(this.bannerInfo, '------------')
      // const urlItems = this.bannerInfo.map(item => item.url);
      this.urlItems = this.bannerInfo.map(item => {
        return { url: item.url };
      });
      console.log(this.urlItems, 'urlItems')
      if( this.bannerInfo.length) {
        this.bannerUrl = this.bannerInfo[0].url 
        this.title = this.bannerInfo[0].title 
        this.subtitle = this.bannerInfo[0].subtitle 
        this.schoolNumber = Number(this.bannerInfo[0].data01)
        this.clientNumber = Number(this.bannerInfo[0].data02)
        this.outletsNumber = Number(this.bannerInfo[0].data03)
        this.consultantNumber = Number(this.bannerInfo[0].data04)
      }
    }
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
}
.banner-div{
  min-width: 1200px;
  height: 680px;
  background: #ffffff;
  margin: 0px auto;
  position: relative;
}
.el-carousel__container {
  height: 680px;
  /* 设置 Carousel 高度为 300 像素 */
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 680px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.banner {
  width: 1200px;
  height: 680px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.slogan {
  width: 1200px;
  height: 309px;
  font-size: 80px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 112px;
  padding-top: 197px;
  /* opacity: 0; */
  text-align: center;
  font-weight: bold;
  /* animation: show 6s ease-out forwards; */
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slogan-tips {
  text-align: right;
  margin-right: 110px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  padding-top: 30px;
  /* opacity: 0; */
  /* animation: show 6s ease-out forwards; */
}

.dataNumber {
  width: 1200px;
  height: 120px;
}

.dataNumber ul {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-top: 116px;
}

.dataNumber ul li {
  flex-basis: 25%;
  /* 每个li元素占据的宽度为整个父容器的1/4 */
  box-sizing: border-box;
  margin-bottom: 30px;
  text-align: center;
}

.f60 {
  font-size: 60px;
  font-family: AppleSystemUIFont;
  color: #ffffff;
  line-height: 71px;
  overflow: hidden;
  font-weight:bolder;
  animation: f60 2s ease-out;
}

.data-1 {
  font-size: 36px;
  font-family: AppleSystemUIFont;
  color: #ffffff;
  line-height: 43px;
}

.f20 {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}

@keyframes f60 {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-100px);
  }

  60% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(-200px);
  }
}
</style>
