<template>
  <div class="public-container" :style="containerStyle">
    <div class="public-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  data() {
    return {
      imageStyle: {
        backgroundRepeat: "no-repeat",
      }
    };
  },
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    img: {
      type: String,
      default: ''
    },
    paddingTop:{
      type: String,
      default: '0px'
    },
    position:{
      type: String,
      default: 'center'
    },
    boxShadow:{
      type: String,
      default: ''
    }
  },
  computed: {
    containerStyle() {
      const style = {
        backgroundColor: this.color,
        paddingTop: this.paddingTop,
        backgroundPosition: this.position,
        boxShadow: this.boxShadow
      };

      if (this.img) {
        style.backgroundImage = `url(${this.img})`;
        Object.assign(style, this.imageStyle);
      }

      return style;
    }
  }
};
</script>