<template>
    <div class="news">
      <Title>新闻动态</Title>
      <div class="des">
        <ul>
          <li v-for="(item, index) in item.slice(0, 3)" :key="index" class="hoverShadow">
            <div class="card">
              <div class="image">
                <router-link :to="`/news/detail/${item.id}`">
                  <img :src="item.coverImage" alt="">
                </router-link>
              </div>
              <div class="time">{{formatDateString(item.releaseTime)}}</div>
              <router-link :to="`/news/detail/${item.id}`">
              <div class="title">
                  {{item.title}}
              </div>
            </router-link>
              <div class="info">{{item.subtitle}}</div>
              <div class="btn"> <button class="go hoverShadow" @click="handleButtonClick(item.categoryId)">更多{{item.categoryName}} <i class="el-icon-right" style="font-size: 14px;"></i></button></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import Title from './title.vue';
import { queryIndexNews } from '../assets/api/api.js'
import { formatDateString } from '@/request/time.js';
export default {
  data () {
    return {
      item: []
    }
  },
  components:{
    Title
  },
  methods: {
    // 时间格式化
    formatDateString(dateString) {
      return formatDateString(dateString); // 调用导入的函数
    },
    async queryIndexNews () {
      const res = await queryIndexNews()
      this.item = res.data
      console.log(res, '获取首页新闻列表')
    },
    handleButtonClick(e) {
      this.$router.push('/news/' + e);
    }
  },
  mounted () {
    this.queryIndexNews()
  }
}
</script>

<style scoped>
.news{
  width: 100%;
}
.card{
  width: 395px;
  height: 510px;
  background: #FFFFFF;
  border-radius: 6px;
}
.des ul{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  list-style-type: none;
  padding: 0;
  padding: 64px 0 80px 0;
}
.des ul li{
  width: 397px;
  margin-right: 2px; /* 默认的间距为10px */
  box-sizing: border-box; /* 确保边框和内边距不会增加元素宽度 */
  margin-bottom: 2px;
}
.des ul li:nth-child(3) {
  margin-right: 0; /* 第四个、第八个... li 元素的间距为0 */
}
.image img{
  width: 397px;
  height: 200px;
}
.time{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9C9C9C;
  text-align: left;
  margin-top: 10px;
  margin-left: 30px;
}
.title{
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  text-align: left;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  width: 350px;
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;

}
.info{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9C9C9C;
  text-align: left;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  width: 350px;
  height: 50px;
  max-width: 350px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;

}
.go{
  width: 142px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #BF1A20;
  background: #ffffff;
  color: #BF1A20;
  cursor: pointer;
}
.go:hover{
  width: 142px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #BF1A20;
  background: #BF1A20;
  color: #ffffff;
}
.btn{
  text-align: left;
  margin-top: 40px;
  margin-left: 30px;
}
.title route{
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  text-align: left;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  width: 350px;
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>