<template>
  <nav>
    <div class="header">
      <div class="header-div">
        <!-- 网站 Logo -->
        <div class="logo">
          <img src="../assets/images/logo.png" alt="logo"/>
        </div>
        <!-- 导航菜单 -->
        <nav class="nav">
          <ul v-if="menuList.length">
            <li v-for="item in menuList" :key="item.id" :class="{ 'has-submenu': item.submenu, active: $route.path && $route.path.replace(/(\/[^/]+).*/, '$1') === item.path && item.path.replace(/(\/[^/]+).*/, '$1') }">
              <router-link v-if="!item.children || !item.children.length" :to="item.path? item.path : `/${item.id}`" class="nav-hove">{{item.label}}</router-link>
              <a v-else class="nav-hove">
                {{item.label}}
              </a>
              <ul class="submenu" v-if="item.children && item.children.length">
                <template v-for="subItem in item.children">
                  <li :key="subItem.id" v-if="subItem.id">
                    <router-link :to="subItem.path?subItem.path: `/${subItem.id}`">{{ subItem.label }}</router-link>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'Header',
  props:{
    menuList:{
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style scoped>
.header-div{
  height: 70px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo{
  height: 33px;
  width: 300px;
}
/* .logo img {
  height: 33px;
  width: 300px;
} */
.nav{
  width: 900px;
  height: 70px;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  margin-left: auto; /* 子元素靠右布局 */
}
.nav ul {
  list-style: none;
  display: flex;

}
.nav li {
  margin-left: 58px;
  position: relative;
}
.nav a {
  display: block;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  border-bottom: 2px solid #fff;
}
.nav-hove.router-link-exact-active {
  display: block;
  color: #BF1A20;
  border-bottom: 2px solid #BF1A20;
}
.nav-hove:hover {
  color: #BF1A20;
  /* line-height: 30px;
  border-bottom: 2px solid #BF1A20; */
}
nav ul li:hover .submenu {
display: block;
}
.has-submenu:hover .submenu {
  /* 鼠标悬停时显示子菜单 */
  display: block;
}
.nav .submenu {
  position: absolute;
  left: -30px;
  /* top: calc(100% + 5px); */
  width: 132px;
  height: 168px;
  z-index: 999;
  display: none;
  /* 初始时隐藏子菜单 */
  top: 100%;
  padding-top: 19px;
  text-align: center;
}


.submenu li {
  margin: 0;
  background-color:#ffffff;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.12);
}

.submenu a {
  font-size: 16px;
  color: #666666;
}
.submenu a:hover {
  /* padding: 8px 16px; */
  font-size: 16px;
  color: #BF1A20;
}
.nav li.active > a {
  display: block;
  color: #BF1A20;
  line-height: 30px;
  border-bottom: 2px solid #BF1A20;
  }
</style>