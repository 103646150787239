var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-div"},[_vm._m(0),_c('div',{staticClass:"footer-link"},[_c('div',{staticClass:"footer-link-left"},[_c('ul',{staticClass:"footer-ul"},[_c('li',[_c('div',{staticClass:"link-title"},[_vm._v("友情链接")]),_c('ul',{staticClass:"links-list"},_vm._l((_vm.linkList.slice(0, 8)),function(items,index){return _c('li',{key:index},[_c('a',{attrs:{"href":items.friendlyLink,"target":"_blank"}},[_vm._v(_vm._s(items.channelName))])])}),0)]),_vm._l((_vm.footNavList.slice(0, 3)),function(item,index){return _c('li',{key:index,staticClass:"ohterLi"},[_c('div',{staticClass:"link-titleItem"},[_vm._v(_vm._s(item.label))]),(item.children)?_c('ul',{staticClass:"links-listItems"},_vm._l((item.children.slice(0, 8)),function(subItem){return _c('li',{key:subItem.id,staticStyle:{"width":"145px"}},[_c('a',{attrs:{"href":subItem.path}},[_vm._v(_vm._s(subItem.label))])])}),0):_vm._e()])})],2)]),_c('div',{staticClass:"footer-link-right"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('ul',{staticClass:"erweima-ul"},_vm._l((_vm.erweimaList.slice(0, 6)),function(erweima,index){return _c('li',{key:index},[_c('div',{staticClass:"erweima-img"},[_c('img',{attrs:{"src":erweima.channelLink}})]),_c('div',[_vm._v(_vm._s(erweima.channelName))])])}),0)])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-logo"},[_c('img',{attrs:{"src":require("../assets/images/logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"company-info"},[_c('li',{staticClass:"footer-right-title"},[_vm._v("客户服务及投诉电话：")]),_c('li',{staticClass:"company-phone"},[_vm._v("4008-900-900")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"company-address"},[_c('li',{staticClass:"footer-right-title"},[_vm._v("公司地址："),_c('span',{staticClass:"f14"},[_vm._v("北京市顺义区天祥路6号院1号楼2层202室（天竺综合保税区）")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"company-email"},[_c('li',{staticClass:"footer-right-title"},[_vm._v("联系邮箱："),_c('span',{staticClass:"f14"},[_vm._v("uib@chinauib.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"company-email"},[_c('li',{staticClass:"footer-right-title"},[_vm._v("网销平台地址："),_c('a',{staticClass:"f14",attrs:{"href":"https://www.chinauib.com.cn","target":"_blank"}},[_vm._v("https://www.chinauib.com.cn")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icp-div"},[_c('ul',[_c('li',{staticClass:"f14"},[_vm._v("Copyright 2018-2023 www.chinauib.com.cn All Rights Reserved")]),_c('li',{staticClass:"f14"},[_vm._v("京ICP备15051113号 版权所有(网站信息共享，如有侵权，请与我们联系)")]),_c('li',{staticClass:"f14"},[_vm._v("中国银保监会网销许可证: 260033000000800")]),_c('li',{staticClass:"f14"},[_c('img',{attrs:{"src":require("../assets/images/beian.png"),"alt":""}}),_c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"20px","line-height":"20px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005903"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":""}}),_c('p',{staticStyle:{"float":"left","height":"20px","line-height":"20px","margin":"0px 0px 0px 5px","color":"#939393"}},[_vm._v("京公网安备 11011302005903号")])])])])])
}]

export { render, staticRenderFns }