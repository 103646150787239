<template>
  <footer>
    <div class="footer">
      <div class="footer-div">
        <div class="footer-logo">
          <img src="../assets/images/logo.png" alt="logo"/>
        </div>
        <div class="footer-link">
          <div class="footer-link-left">
            <ul class="footer-ul">
              <li>
                <div class="link-title">友情链接</div>
                <ul class="links-list">
                  <li v-for="(items, index) in linkList.slice(0, 8)" :key="index"><a :href="items.friendlyLink" target="_blank">{{items.channelName}}</a></li>
                </ul>
              </li>

              <li v-for="(item, index) in footNavList.slice(0, 3)" :key="index" class="ohterLi" >
                <div class="link-titleItem">{{item.label}}</div>
                <ul class="links-listItems" v-if="item.children">
                  <li style="width: 145px;" v-for="subItem in item.children.slice(0, 8)" :key="subItem.id"><a :href="subItem.path">{{subItem.label}}</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="footer-link-right">
            <ul class="company-info" >
              <li class="footer-right-title">客户服务及投诉电话：</li>
              <!-- <li class="company-phone">{{footerInfo.serviceHone}}</li> -->
              <li class="company-phone">4008-900-900</li>
            </ul>
            <ul class="company-address">
              <li class="footer-right-title">公司地址：<span class="f14">北京市顺义区天祥路6号院1号楼2层202室（天竺综合保税区）</span></li>
              <!-- <li class="f14">{{footerInfo.companyAddress}}</li> -->
              <!-- <li class="f14">北京市顺义区天祥路6号院1号楼2层202室（天竺综合保税区）</li> -->
            </ul>
            <ul class="company-email">
              <li class="footer-right-title">联系邮箱：<span class="f14">uib@chinauib.com</span></li>
              <!-- <li class="f14">{{footerInfo.hrEmail}}</li> -->
              <!-- <li class="f14">uib@chinauib.com.cn</li> -->
            </ul>
            <ul class="company-email">
              <li class="footer-right-title">网销平台地址：<a class="f14" href="https://www.chinauib.com.cn" target="_blank">https://www.chinauib.com.cn</a></li>
              <!-- <li class="f14">{{footerInfo.hrEmail}}</li> -->
              <!-- <li class="f14">http://sale.chinauib.com.cn/</li> -->
            </ul>
            <ul class="erweima-ul">
              <li v-for="(erweima, index) in erweimaList.slice(0, 6)" :key="index">
                <div class="erweima-img"><img :src="erweima.channelLink"/></div>
                <div>{{erweima.channelName}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="icp-div">
          <ul>
            <li class="f14">Copyright 2018-2023 www.chinauib.com.cn All Rights Reserved</li>
            <li class="f14">京ICP备15051113号 版权所有(网站信息共享，如有侵权，请与我们联系)</li>
            <li class="f14">中国银保监会网销许可证: 260033000000800</li>
            <li class="f14">
              <img src="../assets/images/beian.png" alt="">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005903" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11011302005903号</p></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getSiteInfo, list, link } from '../assets/api/api.js'
export default {
  name: 'Footer',
  data () {
    return {
      footerInfo:[],
      footNavList: [],
      linkList: [],
      erweimaList: []
    }
  },
  methods: {
    async getSiteInfo () {
      const res = await getSiteInfo()
      this.footerInfo = res.data
      console.log(this.footerInfo, 'footerInfo')
    },
    async list () {
      const res = await list (1)
      this.footNavList = res.data
      console.log(this.footNavList, 'footNavList')
    },
    async link () {
      // type = 1 是友情链接
      const type = 1
      const res = await link (type)
      this.linkList = res.data
      console.log(this.linkList)
    },
    async erweimaLink () {
      const type = 0
      const res = await link(type)
      this.erweimaList = res.data
    }
  },
  created () {
    this.getSiteInfo()
    this.list()
    this.link()
    this.erweimaLink()
  }
}
</script>

<style scoped>

.footer-div{
  width: 1200px;
  height: 635px;
  margin: 0px auto;
  
}
.footer-logo{
  display: flex;
  justify-content: flex-start;
  padding-top: 80px;
  height: 110px;
  width: 1200px;
}
.footer-logo img{
  width: 300px;
  height: 33px;
}
.footer-link{
  width: 1200px;
  height: 368px;
  padding-top: 40px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #EFF2F5 ;
}
.footer-link-left{
  width: 660px;
  height: 328px;
}
.footer-link-right{
  width: 540px;
  height: 328px;

  margin-left: auto; /* 子元素靠右布局 */
}
.link-title{
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  line-height: 25px;
  font-family: PingFangSC-Medium, PingFang SC;
}

.footer-ul{
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #EFF2F5;
}
.footer-ul li{
  text-align: left;
  width: 145px;
}
.links-list{
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 153px;
}
.links-list li a{
  font-weight: 400;
  color: #888888;
  line-height: 32px;
  font-size: 14px;
}
.links-list li a:hover{
  font-weight: 400;
  color: #BF1A20;
  line-height: 32px;
  font-size: 14px;
}
.erweima-ul{
  display: flex;
  flex-direction: row; /* 将子元素设置为水平排列 */
  justify-content: space-between; /* 控制子元素之间的间距 */
}
.footer-link-right ul{
  margin-left: 40px;
  margin-bottom: 25px;
}
.company-phone{
  font-weight: 500;
  color: #111111;
  line-height: 25px;
  font-size: 18px;
}
.company-info, .company-address, .company-email{
  text-align: left;
}
.footer-right-title{
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
}
.erweima-ul li div{
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  line-height: 16px;
  text-align: center;
}
.erweima-img img{
  width: 67px;
  height: 67px;
}
.f14{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 20px;
}
.icp-div{
  width: 1200px;
  height: auto;
}
.icp-div ul{
  width: 1200px;
  margin-top:43px;
}
.icp-div ul li{
  margin-right: 73px;
  margin-bottom: 14px;
  float: left;
}
.ohterLi{
  width: 150px;
}
.footer-ul li:first-child {
  width: 220px;
}
.links-listItems li a{
  font-weight: 400;
  color: #888888;
  line-height: 32px;
  font-size: 14px;
}
.links-listItems li a:hover{
  font-weight: 400;
  color: #BF1A20;
  line-height: 32px;
  font-size: 14px;
}
.link-titleItem{
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  line-height: 25px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.links-listItems li:first-child{
  margin-top: 16px;
}
.listItems_150{
  width: 150px;
}
a.f14:hover{
  color: #BF1A20;
}
</style>