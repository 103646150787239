<template>
  <div class="home">
    <div class="footer-logo">
      <!-- <img src="../../../dist/img/logo.63f3c9de.png" alt=""> -->
    </div>
    <div class="links">
      <span><a href="#">中国银行保险监督委员会</a></span>
      <span><a href="#">教育部</a></span>
      <span><a href="#">新浪新闻网</a></span>
      <span><a href="#">中国行业保险协会</a></span>
      <span><a href="#">中国银行保险</a></span>
      <span><a href="#">中国平安</a></span>
      <span><a href="#">中国人寿</a></span>
      <span><a href="#">太平洋保险</a></span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.home{
  width: 6.826rem;
}
.footer-logo img{
  border: initial;
  max-width: initial;
  height: initial;
}
.links{
  width: 6.826rem;
}
</style>