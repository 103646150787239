import { request } from "@/request/request";

// 获取站点信息
export const getSiteInfo = () => {
  return request({
    url:'/api/siteInfo/getSiteInfo',
    method: 'GET'
  })
}

// 合作伙伴
export const partnerList = () => {
  return request({
    url:'/api/partnerInfo/partnerList',
    method: 'GET'
  })
}


// 头部导航、footer导航的NAV
export const list = (type) => {
  return request({
    url: `/api/categoryInfo/list?type=${type}`,
    method: 'GET'
  })
}

// banner 轮播图

export const bannerList = (id) => {
  return request({
    url: `/api/banner/list?id=${id}`,
    method: 'GET'
  })
}

// 查询首页新闻展示列表
export const queryIndexNews = () => {
  return request({
    url: '/api/contentInfo/queryIndexNews',
    method: 'GET'
  })
}

// 友情链接 二维码
export const link = (type) => {
  return request({
    url: `/api/channel/list?type=${type}`,
    method: 'GET'
  })
}

// 获取部门列表

export const getByProvinceCode = (provinceCode) => {
  return request({
    url: `/api/dept/getByProvinceCode?provinceCode=${provinceCode}`,
    method: 'GET'
  })
}

// 风险管理 联合学堂

export const infoList = (id) => {
  return request({
    url: `/api/tagInfo/list?id=${id}`,
    method: 'GET'
  })
}

//新闻动态-获取新闻列表
export const queryContentList = ({categoryId,tagId = '',pageSize,pageNum}) => {
  return request({
    url: '/api/contentInfo/queryContentList',
    method:'GET',
    params:{
      categoryId,
      tagId,
      pageSize,
      pageNum
    }
  })
}

// 关于我们 企业荣誉 照片墙
export const queryPhotoWalls = (type) => {
  return request({
    url: `/api/photoWall/queryPhotoWalls?type=${type}`,
    method: 'GET'
  })
}

// 获取列表详情

export const queryContentInfo = (id) => {
  return request({
    url: `/api/contentInfo/queryContentInfo?id=${id}`,
    method: 'GET'
  })
}

// 获取联合故事列表信息

export const queryChannelContentList = (id) => {
  return request({
    url: `/api/contentInfo/queryChannelContentList?id=${id}`,
    method: 'GET'
  })
}

/**
 * 获取招聘部门列表
 * @returns 
 */
export const queryJobDeptList = () => {
  return request({
    url:'/api/jobInfo/queryJobDeptList',
  })
}

/**
 * 获取考勤地区列表
 * @returns 
 */
export const queryJobAreaList = () => {
  return request({
    url:'/api/jobInfo/queryJobAreaList',
  })
}

/**
 * 获取招聘信息列表
 * @returns 
 */
export const queryJobList = ({dept="",area="",pageSize=10,pageNum,orderByColumn='',isAsc='',}) => {
  return request({
    url:'/api/jobInfo/list',
    params:{
      dept,
      area,
      pageSize,
      pageNum,
      orderByColumn,
      isAsc
    }
  })
}

/**
 * 获取公司发展历程
 * @returns 
 */
export const queryDevelopHistory = () => {
  return request({
    url:'/api/developHistory/list',
  })
}