<template>
    <div class="container-div">
      <Title>合作伙伴</Title>
      <div class="partners-name" v-for="(container, index) in containers" :key="index" v-show="currentIndex === index">
          <a v-for="(image, imageIndex) in container" :key="imageIndex" :href="image.linkUrl" target="_blank" class="item">
            <img :src="image.logo" style="width: 192px; height: 74px;" />
          </a>
      </div>
      <div class="carousel-controls">
        <span
          v-for="(container, index) in containers"
          :key="index"
          class="carousel-dot"
          :class="{ active: currentIndex === index }"
          @click="changeContainer(index)"
        ></span>
      </div>
    </div>
</template>

<script>
import { partnerList } from '../assets/api/api.js'
import Title from './title.vue';
export default {
  data() {
    return {
      imagesData: [], // 后台传递的图片数组对象
      imagesPerContainer: 18, // 每个容器显示的图片数量
      currentIndex: 0,
      containers: []
    };
  },
  components:{
    Title
  },
  created() {
    
  },
  methods: {
    async partnerList() {
      const res = await partnerList()
      this.imagesData = res.rows
      this.splitImagesIntoContainers(); // 在组件创建时划分图片为容器
      // console.log(this.imagesData,'获取合作伙伴+++')
    },
    splitImagesIntoContainers() {
      for (let i = 0; i < this.imagesData.length; i += this.imagesPerContainer) {
        const container = this.imagesData
          .slice(i, i + this.imagesPerContainer);
        this.containers.push(container);
      }
    },
    changeContainer(index) {
      this.currentIndex = index;
    }
  },
  mounted () {
    this.partnerList()
  }
}
</script>

<style scoped>

  .container-div{
    width: 100%;
    padding-bottom: 80px;
  }
  .partners-name{
    display: flex;
    flex-wrap: wrap; /* 添加了flex-wrap属性 */
    justify-content: flex-start;
    align-content: flex-start; /* 修改 align-content 属性 */
    width: 1200px;
    height: 304px;
    padding-top: 64px;
    text-align: left;
  }
  .item{
    flex-basis: 16.666%;
    height: 74px;
    margin-bottom: 10px;
  }
  .carousel-controls{text-align: center;padding-top: 20px;}
  .carousel-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  margin: 2px;
}

.carousel-dot.active {
  background-color: #BF1A20;
}
</style>