<template>
  <div class="container">
    <div class="details">
      <div class="info">
        <div class="title">
          <p class="f32">{{detail.title}}</p>
          <p class="f14-g">
            <span>发布时间&nbsp;&nbsp;&nbsp;&nbsp;{{ formatDateString(detail.releaseTime) }}</span>
            <span>发布人&nbsp;&nbsp;&nbsp;&nbsp;{{detail.author}}</span>
            <span>发布来源&nbsp;&nbsp;&nbsp;&nbsp;{{detail.source}}</span>
          </p>
          <p class="content" v-html="detail.content"></p>
        </div>
        <div class="other">
          <div class="pre">
            <div v-if="detail.preContent">
              <p class="next-back">上一篇</p>
              <p class="next-title">
                <router-link :to="`/riskmanage/lianheSchool/detail/${detail.preContent.id}`">{{detail.preContent.title}}</router-link>
              </p>
              <p class="f14">发布时间  {{ formatDateString(detail.preContent.releaseTime) }}</p>
            </div>
            <div v-else>
              <p class="next-back">上一篇</p>
              <p class="next-title">暂无信息</p>
            </div>
          </div>
          <div class="next">
            <div v-if="detail.nextContent">
              <p class="next-back">下一篇</p>
              <p class="next-title">
                <router-link :to="`/riskmanage/lianheSchool/detail/${detail.nextContent.id}`">{{detail.nextContent.title}}</router-link>
              </p>
              <p class="f14">发布时间  {{ formatDateString(detail.nextContent.releaseTime) }}</p>
            </div>
            <div v-else>
              <p class="next-back">下一篇</p>
              <p class="next-title">暂无信息</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { queryContentInfo } from '../assets/api/api.js'
import { formatDateString } from '@/request/time.js';

export default {
  data() {
    return {
      detail: {},
      id: '',
      formattedDate: '',
      nextContent: {},
      preContent: {}
    };
  },
  created() {
    // 获取路由参数
    this.id = this.$route.params.id

    // console.log('传递过来的新闻ID:', id)
    this.queryContentInfo()
  },
  methods: {
    // 时间格式化
    formatDateString(dateString) {
      return formatDateString(dateString); // 调用导入的函数
    },
    async queryContentInfo () {
      const res = await queryContentInfo(this.id)
      this.detail = res.data
      console.log(res.data, '文章详情')
      this.nextContent = res.data.nextContent
      this.preContent = res.data.preContent
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id !== this.$route.params.id) {
      // 获取新的新闻ID
      const newId = to.params.id;
      // 执行异步请求以获取新的新闻内容
      queryContentInfo(newId)
        .then(res => {
          // 更新详情页内容
          this.detail = res.data;
          console.log(res, 'res-------------------')
          // 继续进行路由更新
          next();
        })
        .catch(error => {
          console.error(error);
          // 继续进行路由更新
          next();
        });
    } else {
      // 继续进行路由更新
      next();
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #EFF2F5;
  border-top: 1px solid #EFF2F5;
  box-shadow: inset 0 6px 8px -6px rgba(230,230,230,0.5);
}
.details{
  max-width: 1920px;
  width: 100%;
  height: auto;
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.info{
  width: 1000px;
  height: auto;
}
.f32{
  font-size: 32px;
  text-align: left;
  font-weight: 500;
  color: #111111;
  margin-top: 45px;
}
.f14-g{
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  line-height: 20px;
  text-align: left;
  margin-top: 12px;
}
.f14-g span{
  margin-right: 50px;
}
.content{
  margin-top: 36px;
  text-align: left;
  text-indent: 2em;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  line-height: 38px;
}
.other{
  width: 1000px;
  height: 190px;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
}
.pre, .next{
  width: 490px;
  height: 190px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
}
.pre{
  margin-right: 15px;
}
.next-back, .next-title, .f14{
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
}
.next-title{
  
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #111111;
  line-height: 28px;
}
.next-back{
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #111111;
  line-height: 28px;
  border-bottom: 2px solid #BF1A20;
  width: 60px;
  margin-top: 22px;
  margin-bottom: 22px;
}
.f14{
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.next-title a{
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #111111;
  padding-right: 20px;
  line-height: 28px;
  white-space: nowrap;     /* 不换行 */
  overflow: hidden;        /* 溢出隐藏 */
  text-overflow: ellipsis; /* 以省略号表示溢出部分 */
  width: 450px;
  display: block;
}
.next-title a:hover{
  color: #BF1A20;
}
</style>